import React from 'react';

const Footer = ({ connectionStatus }) => {
  const isConnected = connectionStatus === 'Connected';
  
  return (
    <div className="footer">
      <p>© The First Wall Film, 2024</p>
      <ul>
        <li
          id="connection"
          style={{ color: isConnected ? 'green' : 'red' }}
        >
          Connection Status: {connectionStatus}
        </li>
      </ul>
    </div>
  );
};

export default Footer;
