import React, { useEffect, useRef } from 'react';

const WebSocketManager = ({
  socket,
  connectionStatus,
  setConnectionStatus,
  summarizationProgress,
  queuedTasks,
  setQueuedTasks,
  setMessages,
  setMetaData,
  selectedChapter,
  setSelectedChapter,
  setIsSubmitting,
  setStatusMessage,
}) => {
  const reconnectInterval = useRef(null); // Store reference to reconnect interval
  const reconnectAttempts = useRef(0); // Track the number of reconnect attempts

  useEffect(() => {
    const connectWebSocket = () => {
      const url = 'wss://cyberdyneroboticsystemsapi.com/ws';
      console.log('🔵 Attempting to connect to WebSocket:', url);
      socket.current = new WebSocket(url);

      socket.current.onopen = () => {
        console.log('🟢 WebSocket connected');
        setConnectionStatus('Connected');
        reconnectAttempts.current = 0; // Reset reconnect attempts on successful connection

        const lastMessageId = localStorage.getItem('lastMessageId');
        if (lastMessageId) {
          console.log('🟡 Resuming with last message ID:', lastMessageId);
          socket.current.send(
            JSON.stringify({
              mode: 'service',
              service: 'statusUpdate',
              message: { messageId: lastMessageId },
            })
          );
        }
      };

      socket.current.onclose = () => {
        console.log('🔴 WebSocket disconnected');
        setConnectionStatus('Disconnected');
        attemptReconnect();
      };

      socket.current.onerror = (error) => {
        console.error('❌ WebSocket error:', error);
        attemptReconnect();
      };

      socket.current.onmessage = (event) => {
        console.log('📨 Message received:', event.data);
        const data = JSON.parse(event.data);
        console.log('data!', data);

        let response = data?.response?.state?.data;

        if (response) {
          setSelectedChapter(response);

          // Find the corresponding task in queuedTasks and update its status to 'complete'
          setQueuedTasks((prevTasks) =>
            prevTasks.map((task) =>
              task.messageId === response.messageId
                ? { ...task, status: 'complete', response: response, progress: data.progress }
                : task
            )
          );
        }

        if (data.messageId) localStorage.setItem('lastMessageId', data.messageId);


        if (data.service === 'statusUpdate') {
          // Find the corresponding task and mark it as complete
          
          if (data.status === 'complete') {
            setQueuedTasks((prevTasks) =>
              prevTasks.map((task) =>
                task.messageId === data.messageId
                  ? { ...task, status: 'complete', response: data.response, progress: data.progress }
                  : task
              )
            );
          }
          else {
            setQueuedTasks((prevTasks) =>
              prevTasks.map((task) =>
                task.messageId === data.messageId
                  ? { ...task, status: data.status, progress: data.progress }
                  : task
              )
            );
          }
        }



        switch (data.service) {
          case 'metaData':
            console.log('🟡 MetaData received');
            setMetaData(data.data || {});
            break;
          case 'summaryResponse':
            const { chapter, summary, status } = data.data;
            console.log(`🟡 Summary received for chapter ${chapter}: ${status}`);

            if (status === 'done') {
              console.log(`🟢 Summary processing completed for chapter ${chapter}`);
            }
            break;
          case 'updateChapter':
            if (selectedChapter === data.data.chapter) {
              console.log('🟢 Chapter update received and applied');
              setSelectedChapter(data.data.content);
              setMessages([{ author: 'System', content: data.data.content }]);
              setIsSubmitting(false);
              setStatusMessage({ text: 'Success!', type: 'success' });
            }
            break;
          case 'statusUpdate':
            console.log('🟡 statusUpdate:', data);
            break;
          default:
            console.log('⚪️ Unknown message type received:', data);
            break;
        }
      };
    };

    const attemptReconnect = () => {
      if (reconnectAttempts.current < 5) {
        reconnectAttempts.current += 1;
        console.log(`🟠 Attempting to reconnect (#${reconnectAttempts.current})...`);
        reconnectInterval.current = setTimeout(connectWebSocket, 5000); // Retry after 5 seconds
      } else {
        console.log('🔴 Maximum reconnect attempts reached.');
        setConnectionStatus('Failed to reconnect');
      }
    };

    connectWebSocket();

    return () => {
      console.log('🔴 Closing WebSocket connection');
      if (reconnectInterval.current) {
        clearTimeout(reconnectInterval.current); // Clear reconnect timeout on component unmount
      }
      socket.current.close();
    };
  }, [
    socket,
    setConnectionStatus,
    setMetaData,
    setMessages,
    setSelectedChapter,
    setIsSubmitting,
    setStatusMessage,
  ]);

  return null;
};

export default WebSocketManager;
