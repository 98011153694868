import React, { useState, useEffect, useRef } from 'react';
import ChaptersList from './components/ChaptersList/ChaptersList';
import ChapterContent from './components/ChapterContent/ChapterContent';
import MetaData from './components/MetaData/MetaData';
import Tools from './components/Tools/Tools';
import Queue from './components/Queue/Queue';
import Footer from './components/Footer/Footer';
import WebSocketManager from './components/WebSocket/WebSocketManager';
import chaptersData from './book.json';
import metaDataFile from './meta.json';
import './App.css';

const App = () => {
  const [chapters, setChapters] = useState([]); // Default to an empty array
  const [selectedChapter, setSelectedChapter] = useState('');
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [metaData, setMetaData] = useState({});
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [checkedChapters, setCheckedChapters] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [showProgressWindow, setShowProgressWindow] = useState(false);
  const [isReadMode, setIsReadMode] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ text: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [summaries, setSummaries] = useState(JSON.parse(localStorage.getItem('summaries')) || {});
  const [isQueueVisible, setIsQueueVisible] = useState(false);
  const [queuedTasks, setQueuedTasks] = useState(JSON.parse(localStorage.getItem('queuedTasks')) || []);
  const [pasteText, setPasteText] = useState('');
  const [isSummaryMode, setIsSummaryMode] = useState(false);
  const socket = useRef(null);

  useEffect(() => {
    console.log("Loaded chaptersData:", chaptersData);
    const initializedChapters = Array.isArray(chaptersData) ? chaptersData : [];
    setChapters(initializedChapters);
  
    setMetaData(metaDataFile || {});
  
    const storedCheckboxes = JSON.parse(localStorage.getItem('checkedChapters')) || {};
    setCheckedChapters(storedCheckboxes);
  
    const initialCheckedChapters = initializedChapters.reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {});
    setCheckedChapters(initialCheckedChapters);
    setSelectAllChecked(true);

    if (initializedChapters.length > 0) {
      const storedChapters = JSON.parse(localStorage.getItem('chapters')) || initializedChapters;
      setChapters(storedChapters);
      setSelectedChapter(storedChapters[0].content);
      setMessages([{ author: 'System', content: storedChapters[0].content }]);
    }
  }, []);
  

  const handleChapterClick = (chapter, index) => {
    setCurrentChapterIndex(index);
    setSelectedChapter(chapter.content);
    setMessages([{ author: 'System', content: chapter.content }]);
    setStatusMessage({ text: '', type: '' });
  };

  const handlePreviousChapter = () => {
    if (currentChapterIndex > 0) {
      handleChapterClick(chapters[currentChapterIndex - 1], currentChapterIndex - 1);
    }
  };

  const handleNextChapter = () => {
    if (currentChapterIndex < chapters.length - 1) {
      handleChapterClick(chapters[currentChapterIndex + 1], currentChapterIndex + 1);
    }
  };

  const handleSendMessage = () => {
    if (!selectedChapter.trim() || !newMessage.trim()) return;
    setIsSubmitting(true);
    setStatusMessage({ text: '', type: '' });

    const chapterIndex = currentChapterIndex;
    const prompt = newMessage;
    const messageId = `task_${Date.now()}`;

    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(
        JSON.stringify({
          mode: "service",
          service: 'updateChapter',
          curl: null,
          message: { chapter: selectedChapter, content: prompt, messageId }
        })
      );

      const newTask = { messageId, chapterIndex, prompt, status: 'pending', progress: 1 };
      setQueuedTasks((prevTasks) => {
        const updatedTasks = [...prevTasks, newTask];
        localStorage.setItem('queuedTasks', JSON.stringify(updatedTasks));
        return updatedTasks;
      });
    } else {
      setIsSubmitting(false);
      setStatusMessage({ text: 'Error!', type: 'error' });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSendMessage();
  };

  return (
    <div className="app-container">
      <ChaptersList
        chapters={chapters}
        currentChapterIndex={currentChapterIndex}
        handleChapterClick={handleChapterClick}
        checkedChapters={checkedChapters}
        setCheckedChapters={setCheckedChapters}
        selectAllChecked={selectAllChecked}
        setSelectAllChecked={setSelectAllChecked}
        handleSummarizeSelectedChapters={() => setIsSummaryMode(true)}
      />
      <ChapterContent
        chapters={chapters}
        selectedChapter={selectedChapter}
        currentChapterIndex={currentChapterIndex + 1}
        setSelectedChapter={setSelectedChapter}
        isReadMode={isReadMode}
        handlePreviousChapter={handlePreviousChapter}
        handleNextChapter={handleNextChapter}
        handleToggleReadMode={() => setIsReadMode(!isReadMode)}
        handleSaveChapter={() => {
          const updatedChapters = [...chapters];
          updatedChapters[currentChapterIndex] = { ...updatedChapters[currentChapterIndex], content: selectedChapter };
          setChapters(updatedChapters);
          localStorage.setItem('chapters', JSON.stringify(updatedChapters));
          setStatusMessage({ text: 'Chapter saved!', type: 'success' });
        }}
        statusMessage={statusMessage}
        renderStatusMessage={() => isSubmitting ? <span className="status-message spinner">Processing...</span> : null}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        handleKeyDown={handleKeyDown}
        handleSendMessage={handleSendMessage}
      />
      <WebSocketManager
        socket={socket}
        connectionStatus={connectionStatus}
        setConnectionStatus={setConnectionStatus}
        queuedTasks={queuedTasks}
        setQueuedTasks={setQueuedTasks}
        setMessages={setMessages}
        setMetaData={setMetaData}
        selectedChapter={selectedChapter}
        setSelectedChapter={setSelectedChapter}
        setIsSubmitting={setIsSubmitting}
        setStatusMessage={setStatusMessage}
      />
      <div className="right-panel">

        <Tools
          chapters={chapters}
          metaData={metaData}
          setChapters={setChapters}
          setSelectedChapter={setSelectedChapter}
          setMessages={setMessages}
          setQueuedTasks={setQueuedTasks}
          summaries={summaries}
          setSummaries={setSummaries}
          pasteText={pasteText}
          setPasteText={setPasteText}
          handleClearSummaries={() => {
            localStorage.removeItem('summaries');
            setSummaries({});
          }}
          handlePasteTextSummarization={() => setIsSummaryMode(true)}
        />
        <button
          className="process-dashboard-btn"
          onClick={() => setIsQueueVisible(true)}
        >
          Process Dashboard
        </button>
        <MetaData
          metaData={metaData}
          setMetaData={setMetaData}
        />
        <Footer connectionStatus={connectionStatus} />
      </div>
      <Queue
        isQueueVisible={isQueueVisible}
        setIsQueueVisible={setIsQueueVisible}
        queuedTasks={queuedTasks}
        setQueuedTasks={setQueuedTasks}
        showProgressWindow={showProgressWindow}
        setShowProgressWindow={setShowProgressWindow}
      />
    </div>
  );
};

export default App;
