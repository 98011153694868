import React from 'react';

const Queue = ({
  isQueueVisible,
  setIsQueueVisible,
  queuedTasks,
  setQueuedTasks,
}) => {
  const handleDeleteTask = (taskId) => {
    const updatedTasks = queuedTasks.filter((task) => task.messageId !== taskId);
    setQueuedTasks(updatedTasks);
    localStorage.setItem('queuedTasks', JSON.stringify(updatedTasks));
  };

  console.log('queuedTasks', queuedTasks)

  return isQueueVisible ? (
    <div className="summarization-progress">
      <button className="close-button" onClick={() => setIsQueueVisible(false)}>&times;</button>
      <h2>Process Queue</h2>
      <table className="summarization-table">
        <thead>
          <tr>
            <th>Task ID</th>
            <th>Prompt</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {queuedTasks.map((task, index) => (
            
            <tr key={index}>
              <td>{task.messageId}</td>
              <td>{task.prompt}</td>
              <td>
                <progress value={task?.progress || 0} max="100"></progress>
              </td>
              <td>{task.status}</td>
              <td>
                <button onClick={() => handleDeleteTask(task.messageId)} className="button delete-button">❌</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default Queue;
