import React, { useState } from 'react';

const MetaData = ({ metaData, setMetaData }) => {
  const [isOpen, setIsOpen] = useState(true); // State to track if the fieldset is open

  const toggleFieldset = () => {
    setIsOpen((prev) => !prev);
  };

  const renderTree = (obj, path = []) => {
    if (typeof obj !== 'object' || obj === null) return null;

    return Object.keys(obj).map((key) => {
      const fullPath = [...path, key];
      const value = obj[key];

      if (Array.isArray(value)) {
        return (
          <details key={key} className="metadata-group">
            <summary className="metadata-summary">{key}</summary>
            <div className="metadata-array">
              {value.map((item, index) => {
                const itemLabel = typeof item === 'object' && item !== null
                  ? item.name || item.title || `Item ${index + 1}`
                  : item;
                return (
                  <div key={index} className="metadata-array-item">
                    <strong className="metadata-item-label">{itemLabel}:</strong>
                    {typeof item === 'object' && item !== null ? (
                      renderTree(item, [...fullPath, index])
                    ) : (
                      <div className="metadata-item-controls">
                        <input
                          type="text"
                          className="metadata-input"
                          value={item}
                          onChange={(e) => handleArrayChange(fullPath, index, e.target.value)}
                        />
                        <button onClick={() => handleCopyItem(fullPath, index)}>📋</button>
                        <button onClick={() => handleDeleteItem(fullPath, index)}>❌</button>
                      </div>
                    )}
                  </div>
                );
              })}
              <button onClick={() => handleAddItem(fullPath)}>➕ Add Item</button>
            </div>
          </details>
        );
      }

      if (typeof value === 'object' && value !== null) {
        return (
          <details key={key} className="metadata-group">
            <summary className="metadata-summary">{key}</summary>
            <div className="metadata-object">
              {renderTree(value, fullPath)}
            </div>
          </details>
        );
      }

      return (
        <div key={key} className="metadata-item">
          <strong className="metadata-item-label">{key}:</strong>
          <div className="metadata-item-controls">
            <input
              type="text"
              className="metadata-input"
              value={value}
              onChange={(e) => handleMetaDataChange(fullPath, e.target.value)}
            />
            <button onClick={() => handleCopyItem(fullPath)}>📋</button>
            <button onClick={() => handleDeleteItem(fullPath)}>❌</button>
          </div>
        </div>
      );
    });
  };

  const handleMetaDataChange = (path, value) => {
    setMetaData((prevMetaData) => {
      const updatedMetaData = { ...prevMetaData };
      let current = updatedMetaData;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] = value;
      return updatedMetaData;
    });
  };

  const handleArrayChange = (path, index, value) => {
    setMetaData((prevMetaData) => {
      const updatedMetaData = { ...prevMetaData };
      let current = updatedMetaData;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]][index] = value;
      return updatedMetaData;
    });
  };

  const handleAddItem = (path) => {
    setMetaData((prevMetaData) => {
      const updatedMetaData = { ...prevMetaData };
      let current = updatedMetaData;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]].push(''); // Adding an empty item or object as necessary
      return updatedMetaData;
    });
  };

  const handleDeleteItem = (path, index = null) => {
    setMetaData((prevMetaData) => {
      const updatedMetaData = { ...prevMetaData };
      let current = updatedMetaData;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      if (index !== null) {
        current[path[path.length - 1]].splice(index, 1);
      } else {
        delete current[path[path.length - 1]];
      }
      return updatedMetaData;
    });
  };

  const handleCopyItem = (path, index = null) => {
    setMetaData((prevMetaData) => {
      const updatedMetaData = { ...prevMetaData };
      let current = updatedMetaData;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      if (index !== null) {
        const itemToCopy = current[path[path.length - 1]][index];
        current[path[path.length - 1]].splice(index + 1, 0, itemToCopy);
      } else {
        current[path[path.length - 1]] = JSON.parse(JSON.stringify(current[path[path.length - 1]]));
      }
      return updatedMetaData;
    });
  };

  return (
    <fieldset className="metadata-container" open={isOpen}>
      <legend className="metadata-title" onClick={toggleFieldset}>
      MetaData
      </legend>

      
      {metaData ? (
        <div className="metadata-content">{renderTree(metaData)}</div>
      ) : (
        <div className="metadata-empty">No metadata available</div>
      )}
      <button
        onClick={() => localStorage.setItem('metaData', JSON.stringify(metaData))}
        className="button save-button"
      >
        Save Metadata
      </button>
    </fieldset>
  );
};

export default MetaData;
