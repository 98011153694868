import React from 'react';

const ChapterContent = ({
  selectedChapter,
  setSelectedChapter,
  currentChapterIndex,
  isReadMode,
  handlePreviousChapter,
  handleNextChapter,
  handleToggleReadMode,
  handleSaveChapter,
  statusMessage,
  renderStatusMessage,
  newMessage,
  setNewMessage,
  handleKeyDown,
  handleSendMessage
}) => {
  if (!selectedChapter) {
    return <div>No chapter selected</div>;
  }



  return (
    <div className="main-panel chapter-content">
      <h1></h1>
      <h2>Chapter {currentChapterIndex}</h2> {/* Chapter number displayed here */}

      <div className="chapter-navigation">
        <button onClick={handlePreviousChapter} disabled={!selectedChapter}>
          Previous
        </button>
        <button onClick={handleNextChapter} disabled={!selectedChapter}>
          Next
        </button>
        <button onClick={handleToggleReadMode}>
          {isReadMode ? 'Exit Read Mode' : 'Enter Read Mode'}
        </button>
        <button onClick={handleSaveChapter}>
          Save Chapter
        </button>
      </div>
      <textarea
        className={`selected-chapter ${isReadMode ? 'read-mode' : ''}`}
        value={selectedChapter || ''} // Ensure safe access to content
        onChange={(e) => setSelectedChapter({ ...selectedChapter, content: e.target.value })}
      />
      {renderStatusMessage && renderStatusMessage()}
      <div className="send-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
      {statusMessage.text && (
        <div className={`status-message ${statusMessage.type}`}>
          {statusMessage.text}
        </div>
      )}
    </div>
  );
};

export default ChapterContent;
