import React from 'react';

const ChaptersList = ({
  chapters = [], // Default to an empty array
  currentChapterIndex,
  handleChapterClick,
  checkedChapters,
  setCheckedChapters,
  selectAllChecked,
  setSelectAllChecked,
  handleSummarizeSelectedChapters
}) => {
  const handleCheckboxChange = (index) => {
    const updatedCheckedChapters = {
      ...checkedChapters,
      [index]: !checkedChapters[index],
    };
    setCheckedChapters(updatedCheckedChapters);
    const allChecked = Object.values(updatedCheckedChapters).every((val) => val === true);
    setSelectAllChecked(allChecked);
    localStorage.setItem('checkedChapters', JSON.stringify(updatedCheckedChapters));
  };

  const handleSelectAllChange = () => {
    const newCheckedState = !selectAllChecked;
    const updatedCheckedChapters = chapters.reduce((acc, _, index) => {
      acc[index] = newCheckedState;
      return acc;
    }, {});
    setCheckedChapters(updatedCheckedChapters);
    setSelectAllChecked(newCheckedState);
    localStorage.setItem('checkedChapters', JSON.stringify(updatedCheckedChapters));
  };

  return (
    <div className="left-panel">
      <h3>Chapters</h3>
      <div className="select-all-container">
        <input
          type="checkbox"
          checked={selectAllChecked}
          onChange={handleSelectAllChange}
        />
        <label>Select All</label>
      </div>
      <ul>
        {Array.isArray(chapters) && chapters.length > 0 ? (
          chapters.map((chapter, index) => (
            <li
              key={index}
              className={`chapter-item ${index === currentChapterIndex ? 'active' : ''}`}
            >
              <input
                type="checkbox"
                checked={checkedChapters[index] || false}
                onChange={() => handleCheckboxChange(index)}
              />
              <span onClick={() => handleChapterClick(chapter, index)}>
                Chapter {index + 1}
              </span>
            </li>
          ))
        ) : (
          <li>No chapters available</li>
        )}
      </ul>
      <button onClick={handleSummarizeSelectedChapters} className="button summarize-button">
        Summarize Selected Chapters
      </button>
    </div>
  );
};

export default ChaptersList;
